/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Con el programa gqcam podemos controlar nuestra cam mediante una interfaz grafica o también por linea de comandos. Esta segunda opción es interesante cuando queremos hacer un script que utilice la cam."), "\n", React.createElement(_components.p, null, "Lo primero que haré será instalar el programa:"), "\n", React.createElement(_components.p, null, "$ ", React.createElement(_components.strong, null, "apt-get install gqcam")), "\n", React.createElement(_components.p, null, "Ahora abrimos la interfaz grafica."), "\n", React.createElement(_components.p, null, "$ ", React.createElement(_components.strong, null, "gqcam -v /dev/video0")), "\n", React.createElement(_components.p, null, "El parametro -v sirve para especificar que cam estoy utilizando si no lo pongo me sale un error porque busca el archivo /dev/video que no existe."), "\n", React.createElement(_components.p, null, "Ajustamos el brillo y el contraste para que se vea bien y nos apuntamos los con que nivel de brillo y contraste han quedado.  En mi caso brillo 43 y contraste 63."), "\n", React.createElement(_components.p, null, "Ya podemos hacer fotos mediante el siguiente comando:"), "\n", React.createElement(_components.p, null, "$ ", React.createElement(_components.strong, null, "gqcam -b 43 -c 63  -s -t JPEG -d fotocam.jpg -v /dev/video0")), "\n", React.createElement(_components.p, null, "El parametro -b indica el brillo, el -c indica el contraste, -s corrige los colores, -t el formato en que se va a guardar la foto, puede ser JPEG, PNG ó PPM y -d indica donde se guardará la foto."), "\n", React.createElement(_components.p, null, "Bueno ya está, lo unico que muchas veces la foto no consigue hacerse bien y hay que volver a conseguir los niveles brillo y contraste."), "\n", React.createElement(_components.p, null, "Un saludo."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
